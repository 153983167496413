import React, { useState, useEffect } from 'react';
import PreviewImage from './preview'
import DraggableText from './text'
import StorageService from "../../services/StorageService";

import './style.css';


function calculateProportionalDimensions(originalWidth, originalHeight, originalBoxWidth, originalBoxHeight, newWidth) {
    // Calculate the width ratio
    const widthRatio = newWidth / originalWidth;
  
    // Calculate the new box width based on the width ratio
    const newBoxWidth = originalBoxWidth * widthRatio;
  
    // Calculate the height ratio based on the smaller dimension to preserve aspect ratio
    const heightRatio = Math.min(newWidth / originalWidth, newBoxWidth / originalBoxWidth);
  
    // Calculate the new box height based on the height ratio
    const newBoxHeight = originalBoxHeight * heightRatio;
  
    return { width:newBoxWidth,height:newBoxHeight };
  }

  function calculateRenderedCoordinates(originalResolution, renderedResolution, referencePoint) {
    const originalX = originalResolution.x;
    const originalY = originalResolution.y;

    const renderedX = renderedResolution.x;
    const renderedY = renderedResolution.y;

    const referenceX = referencePoint.x;
    const referenceY = referencePoint.y;

    // Calculate the ratio
    const xRatio = renderedX / originalX;
    const yRatio = renderedY / originalY;

    // Calculate the corresponding coordinates
    const renderedXCoordinate = Math.round(referenceX * xRatio);
    const renderedYCoordinate = Math.round(referenceY * yRatio);

    return { x: renderedXCoordinate, y: renderedYCoordinate };
}

  

const TemplateImage = ({image, onChangeHeight, onChangeWidth, configData}) => {
  const templateUrl = configData.baseUrl;
  
  const [imageSrc, setImageSrc] = useState(image);
  const [isLoading,setIsLoading] = useState(true);
  const [imageWidth, setImageWidth] = useState(null);
  const [imageHeight, setImageHeight] = useState(null);
  const [renderImageHeight, setRenderImageHeight] = useState('100%');
  const [renderImageWidth, setRenderImageWidth] = useState('100%');

  const [userDetails,setUserDetails] = useState(JSON.parse(StorageService.getData("user_details")));
  const [renderImageResolution,setRenderImageResolution] = useState(configData.image_resolution);
  
  const [renderAxisImage, setRenderAxisImage] = useState(configData.image_axis); 

  
  
//   useEffect(() => {
//     const imageElement = document.getElementById('myImage');
//     console.log(imageElement.offsetHeight);
//     setRenderedWidth(imageElement.offsetWidth);
//     setRenderedHeight(imageElement.offsetHeight);
//     let newValue = calculateProportionalDimensions(imageWidth, imageHeight, 783, 1124,imageElement.offsetWidth );
//     setBoxResolution(newValue);
//     console.log(newValue, imageWidth, imageHeight, 783, 1124,imageElement.offsetWidth );
//   }, []);

const TDimension = (event) =>{
  let target = event.target;
  onChangeHeight(event.target.naturalHeight);
  onChangeWidth(event.target.naturalWidth);

  setImageHeight(event.target.naturalHeight);
  setImageWidth(event.target.naturalWidth);
  setRenderImageHeight(target.height -2);
  setRenderImageWidth(target.width - 2);
    let originalResolution = {x:target.naturalHeight,y:target.naturalWidth};
    let renderedResolution = {x:target.height,y:target.width};
    
    // Calculate Image Render Axis
    setRenderAxisImage(calculateRenderedCoordinates(originalResolution,renderedResolution, renderAxisImage));
  
    //Calculate Image Render Resolution
    setRenderImageResolution(calculateProportionalDimensions(target.naturalWidth, target.naturalHeight, renderImageResolution.width, renderImageResolution.height, target.clientWidth ));

    setIsLoading(false);
}

  return (
    // <div className="pageOuter">
    // <div className="headerGreenBlock">
    //   <div className="headerOuter">
    <>
        {/* <button onClick={Download}>Download</button> */}
            {/* <input type="file"  accept="image/*" onChange={handleImageUpload} /> 
            replace('https://dev.api.eventforce.ai/','serverimage/')
            */}
            
            {
              configData.baseUrl &&
            
        <div style={{position:'relative',height:renderImageHeight, width:renderImageWidth}} id="certificate">
            <img src={templateUrl} id="myImage" className='imagetemplate' onLoad={TDimension} alt="Template Image" />          
            {imageSrc && <PreviewImage x={renderAxisImage.x} y={renderAxisImage.y} width={renderImageResolution?.width} height={renderImageResolution?.height} imageSrc={imageSrc} />}
          
        </div> ||
        <p>Data not found for selected BIB. Please contact with organizer.</p>
        }
        </>
    // </div>    
    // </div>
    // </div>

  );
};

export default TemplateImage;

import React from "react";
import "./style.css";
import { withRouter } from "react-router";
import LeftDrawer from "../../components/leftDrawer";
import ApiService from "../../services/ApiService";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import StorageService from "../../services/StorageService";
// import ImageGallery from '../../components/MasonryListing/v1'
import AlertDialog from '../../components/Modal/v1'
import CertificateStudio from '../Certificate';
import S3 from "../../services/aws/s3";
import * as htmlToImage from 'html-to-image';
import Button from '@mui/material/Button';
import axios from 'axios'

import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
  LinkedinIcon,
  PinterestIcon,
  TelegramIcon,
  TwitterIcon
} from "react-share";


function calculatePixel(desiredPixel, pixelRatio) {
  pixelRatio = window.devicePixelRatio;
  // Check if pixel ratio is 1
  if (pixelRatio === 1) {
    return desiredPixel;
  } else {
    // Divide desired pixel by pixel ratio to get actual pixel
    return desiredPixel / pixelRatio;
  }
}


const SocialShare = ({url,quote,hashtag})=>{
  return (
      <div style={{display:'flex', justifyContent:'center'}}>
      <FacebookShareButton  openShareDialogOnClick={url !== "none"} url={url} quote={quote} hashtag={hashtag} ><FacebookIcon /></FacebookShareButton>
      <WhatsappShareButton url={url} title={quote}> <WhatsappIcon/> </WhatsappShareButton>
      <TwitterShareButton url={url} ><TwitterIcon/></TwitterShareButton>
      <LinkedinShareButton url={url} ><LinkedinIcon/> </LinkedinShareButton>      
      <TelegramShareButton url={url} ><TelegramIcon/></TelegramShareButton>
      </div>
  )
}



async function getImageAsDataURL(url) {
  try {
    const response = await axios.get(url, { responseType: 'blob' });

    const reader = new FileReader();
    reader.readAsDataURL(response.data);

    await new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

    const dataURL = reader.result;
    return dataURL.replace('binary/octet-stream','image/jpeg');
  } catch (error) {
    console.error('Error fetching or converting image:', error);
    throw error; // Re-throw to allow error handling at a higher level
  }
}

class FaceRecognitionSearch extends React.Component
{


  constructor(props){
    super(props);
    let campainData =  StorageService.getData("campaign_data")  ? JSON.parse(StorageService.getData("campaign_data")) : [];
    this.state = {
        isLoading:true,
        campainData:campainData,
        templates:[],
        selectedData:null,      
        selectedImage:null,  
        isBtnLoading:false,   
        isError:'',   
        imageHeight:null,
        imageWidth:null,
        imageWidth:null,

    }
  }
   

   Download = () =>{   
    // console.log(imageWidth, imageHeight)
    // return;
    const filter = (node) => {
      const exclusionClasses = ['Buttons','no-print'];
      return !exclusionClasses.some((classname) => node.classList?.contains(classname));
    }
    htmlToImage.toJpeg(document.getElementById('certificate'), {
        canvasHeight:calculatePixel(this.state.imageHeight),
        canvasWidth:calculatePixel(this.state.imageWidth),
        filter,        
      })
    .then(function (dataUrl) {
      var link = document.createElement('a');
      link.download = 'certificate.jpeg';
      link.href = dataUrl;
      link.click();
    });
  }


   handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => this.setState({selectedImage:e.target.result});
    reader.readAsDataURL(file);
  };

  async selectTemplate(item){
   
   let image =  await getImageAsDataURL(item.image)
    this.setState({selectedData:image});
  }
  componentDidMount(){
    ApiService.getData("getPreeventTemplates", {}, true,`/${this.state.campainData?.id}`).then((response) => {
        if (response.status == "success") {

            this.setState({templates:response.data});
        }
        this.setState({isLoading:false});
      }).catch(err=>{
        this.setState({isLoading:false});
      })
  }


  createImage(){
    let thisVal = this;
    this.setState({isBtnLoading:true,isError:null});
    const filter = (node) => {
      const exclusionClasses = ['Buttons','no-print'];
      return !exclusionClasses.some((classname) => node.classList?.contains(classname));
    }
    let options = {
      canvasHeight:calculatePixel(this.state.imageHeight),
      canvasWidth:calculatePixel(this.state.imageWidth),
      filter,
    };
    htmlToImage.toJpeg(document.getElementById('certificate'), options)
    .then(function (dataUrl) {
      let timestamp = + new Date();     
      S3.uploadCertificate(dataUrl,timestamp+'certificate.jpeg',(data)=>{
            thisVal.setState({certificate:data.Location, isError:false,isBtnLoading:false});
        })
    }).catch(err=>{
      this.setState({isBtnLoading:false, isError:'Somethig went wrong. Please try again later'});      
    });
  }


    render(){
        return(
            <div className="pageOuter" style={{background:this.state.bg}}>   
                    <div className="privacyGreenBlock">
                        <div className="headerOuter">
                            <LeftDrawer></LeftDrawer>
                            <h4 className="headerTitle"></h4>
                        </div>                       
                        <div className="ppOuter">
                            <h4 className="ppTitle">Select Your Template</h4>
                            <h3 className="ppTitle"></h3>
                        </div>   
                        </div>   
                        {
                          this.state.isLoading &&
                          <p className="text-center">Please wait. Templates are loading...</p>
                        }
                        <div style={{display:'flex', justifyContent:'center'}}>
                        <div style={{width:'90%',paddingBottom:50}}>      
                            {
                              (this.state.selectedData && !this.state.selectedImage) &&
                              <div >
                              <h4>Choose Your Image To Generate Template</h4>
                              <input type="file"  accept="image/*" onChange={(event)=>this.handleImageUpload(event)} />
                            </div>
                            }
                       
                        
                        {
                          (this.state.selectedImage) &&
                          <div>
                          <CertificateStudio
                          onChangeWidth ={(width) => {this.setState({imageWidth:width})}}
                          onChangeHeight={(height) => {this.setState({imageHeight:height})}}
                          image={this.state.selectedImage}
                          configData={
                            {
                              baseUrl:this.state.selectedData,
                              image_axis:{x:215,y:0},
                              image_resolution:{width:1784,height:1586},
                            }

                          }
                          />
                          <div className="text-center">
                          <Button onClick={()=>this.createImage()} autoFocus>
                          {this.state.isBtnLoading ? 'Generating...'  : 'Generate'}
                        </Button>  

                         {
                          this.state.certificate &&
                          <>
                          <SocialShare 
                          url={this.state.certificate} 
                          quote={'Greater Bengaluru Bidadi Half Marathon 2024'}
                          hashtag={'#bmm #bangalore #marathon'}
                          />
                        <Button onClick={()=>{this.Download()}}>Download</Button>
                          </>
                      } 
                      </div>
                        </div>
                        }
                          

                          {
                             !this.state.selectedData &&

                             <ImageList sx={{ width: '100%', height: 450, }} cols={1} rowHeight={164}>
                             {this.state.templates.map((item) => (
                               <ImageListItem key={item.img}>
                                 <img
                                 onClick={()=>this.selectTemplate(item)}
                                   srcSet={`${item.image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                   src={`${item.image}?w=164&h=164&fit=crop&auto=format`}
                                   loading="lazy"
                                 />
                               </ImageListItem>
                             ))}
                           </ImageList>
                          }

                              
                          
                        </div>      
                        </div>                                       
                </div>
        )
    }
}

export default withRouter(FaceRecognitionSearch);

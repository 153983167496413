import React from "react";
import "./style.css";
import SocialButton from "./../../components/SocialButton";
import StorageService from "../../services/StorageService";
import { withRouter } from "react-router";
import ApiService from "../../services/ApiService";
import AuthenticationService from "../../services/AuthenticationService";
import { Constants } from "../../config";
import loading from "../../assets/images/loading.gif";
import {BiUserCircle} from 'react-icons/bi'
import appToast from '../../services/toast'

const IS_CERTIFICATE_APP = Constants.IS_CERTIFICATE_APP;
const logoStyle = (logo) => ({
  backgroundColor: `#fff`,
  backgroundImage: `url(${logo})`,
});

class Login extends React.Component {

  componentDidMount(){
    let params = new URLSearchParams(window.location.search);
    StorageService.storeData('guest_image','');
    ApiService.getData('getCapaign', {slug:StorageService.getData("campaign_name") ? StorageService.getData("campaign_name") : params.get("campaign_name")}, false).then((response) => {
      if(!!response.data) {
        if(response.data[0]?.image_console_id !=undefined) {
          StorageService.storeData('campaign_data',JSON.stringify(response.data[0]));
          StorageService.storeData('fr_event_id',response.data[0]?.image_console_id);
          this.getPaymentSlab(response.data[0]?.image_console_id);
        }else{
          StorageService.storeData('campaign_data','{}');
        }
        this.setState({capaignDetails:response.data[0]})
      }
    });

    
      }


      /**
   * Get Payment Slabs
   */
  getPaymentSlab(event_id){
    ApiService.imgConsoleGetData(`events/payment/participant/detailed-options?event_id=${event_id}`, {}, true).then(
      (response) => {
        let priceDetails = response[0];
        let priceSlab = Object.values(priceDetails)[0];
        priceSlab.id = Object.keys(priceDetails)[0];

        StorageService.storeData('paymentSlab',JSON.stringify(priceSlab));
      })

  }


  showWelcomeMessage(){
    
    if(!StorageService.getData("is_shown_welcome_message")) {
      appToast.welcome();
      StorageService.storeData('is_shown_welcome_message','viewed');
    }
  }

  constructor(props) {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let event_name = params.get("event_name");
    let campaign_name =  StorageService.getData("campaign_name") ? StorageService.getData("campaign_name") : params.get("campaign_name");
    let app_type = StorageService.getData("app_type") ? StorageService.getData("app_type") : params.get("app_type");
    let event_url = params.get("event");

    super(props);

  
    AuthenticationService.isCampaignActive(campaign_name, event_name);

    // AuthenticationService.isCampaignActive(
    //   this.props.match.params["campaign_name"],
    //   this.props.match.params["event_name"]
    // );

    this.state = {
      capaignDetails:{},
      // event_logo: StorageService.getData("event_logo")
      //   ? StorageService.getData("event_logo")
      //   : "",
      // event_logo:'https://admin.eventforce.ai/assets/img/velo-logo.png',
      // event_logo:' https://api.eventforce.ai//storage/campaign/campaign-logo/45803347.png', //Certificate

     
      isLoading: false,
    };

    this.getEventDetailsByFriendlyURL(
      app_type == "photo-app" ? event_url : event_name
    );

    setTimeout(()=>{this.showWelcomeMessage()},1000);
  }
  // Facebook login
  handleFBLogin = (user) => {
    let userDetails = {
      firstName: user._profile.firstName,
      lastName: user._profile.lastName,
      email: user._profile.email,
      id: user._profile.id,
    };

    this.callLoginApi(user._token.accessToken, userDetails, "facebook");
  };

  handleFBLoginFailure = (err) => {
    console.error(err);
  };

  // Google Login
  handleGoogleLogin = (user) => {   
    let userDetails = {
      firstName: user._profile.firstName,
      lastName: user._profile.lastName,
      email: user._profile.email,
      id: user._profile.id,
    };
    this.callLoginApi(user._token.accessToken, userDetails, "google");
  };

  handleGoogleLoginFailure = (err) => {
    console.error(err);
  };

  callLoginApi(token, userDetails, type) {
 
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let event = params.get("event");
    let event_name = params.get("event_name");
    let campaign_name =  StorageService.getData("campaign_name") ? StorageService.getData("campaign_name") : params.get("campaign_name");
    let app_type = StorageService.getData("app_type") ? StorageService.getData("app_type") : params.get("app_type");
 
    let postParams = {
      token: token,
    };

    let apiPath = "";

    if (type === "google") apiPath = "googleLogin";
    else apiPath = "facebookLogin";

    ApiService.postData(apiPath, postParams, false).then((response) => {
    
      if (response.status == "success") {

        let {data} = response;
        let userData ={
          ...userDetails,
          ...data.user,
        };
 
        StorageService.storeData("access_token", response.token);
        StorageService.storeData("user_details", JSON.stringify(userData));
      
        let guestImage = StorageService.getData("guest_image");
        if(guestImage){ //Save Guest Image
              let newData = {
                ...userData,
                profile_image:guestImage,
            };
            StorageService.storeData("user_details", JSON.stringify(newData));

              let postParams = {
                profile_image:guestImage,
            };
              ApiService.postData("updateProfileImage", postParams, true).then(
                (response) => {                    
                }).catch(e=>{
                    this.setState({isLoading:false});
                })
        }

        // console.log('Login Response', response);
      //For feature conference  -end
      // console.log('CampaignDetails', this.state.capaignDetails); return;
      
        if(this.state.capaignDetails?.type ==3){

          //For feature conference 
        if(!response.data.profilecompletion){
          this.props.history.push("/" + event_name + "/" + campaign_name + "/complete-profile");
          return;
        }

          this.props.history.push(
            "/" + event_name + "/" + campaign_name + "/speakers"
          );
          return;
        }

        if(this.state.capaignDetails?.type ==4){
          this.props.history.push("/find-your-photo?app_type=photo-app");
          return;

        }
       
        if (app_type == "photo-app") {
          if (event && event != "")
            this.props.history.push(
              "/find-your-photo?app_type=" + app_type + "&event=" + event
            );
          else this.props.history.push("/find-your-photo?app_type=" + app_type);
        } else{
          this.props.history.push(
            "/" + event_name + "/" + campaign_name + "/home"
          );
        }
      } else {
        console.log('Final Else ');

     
      }
    });
  }

  getEventDetailsByFriendlyURL(event_url) {
    if (event_url && event_url != null && event_url != "") {
      setTimeout(() => {
        this.setState({
          isLoading: true,
        });
      }, 10);

      let params = {
        event_url: event_url,
      };
      ApiService.getData("getByEventFriendlyName", params, true).then(
        (response) => {
          if (response.status == "success") {
            this.setState({ event_logo: response.data[0].logo });
            StorageService.storeData("event_logo", response.data[0].logo);
            StorageService.storeData("event_title", response.data[0].title);
            setTimeout(() => {
              this.setState({ isLoading: false });
            }, 1000);
          } else {
            this.setState({ isLoading: false });
            this.setState({
              event_logo: Constants.APP_LOGO,
            });
            StorageService.storeData("event_logo", Constants.APP_LOGO);
            StorageService.storeData("event_title", "Eventforce");
          }
        }
      );
    } else {
      this.setState({
        event_logo: Constants.APP_LOGO,
      });
      StorageService.storeData("event_logo", Constants.APP_LOGO);
      StorageService.storeData("event_title", "Eventforce");
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    return (
      <div className="loginOuter">
        <div className="logoMain">
          <div
            className="logoOuter"
            style={logoStyle(
              this.state.isLoading ? loading  :this.state.event_logo
            )}
          >
            {/* <img
              alt="Logo"
              src={this.state.event_logo}
              // src={require("../../assets/images/landing/logo.png")}
              className="appLogo"
            /> */}
          </div>
        </div>
        {console.log(Constants.IS_CERTIFICATE_APP,'Certificate App')}
        <div className="contentMainOuter">
          <div className="contentHead">
            <h1>LOGIN & REGISTER</h1>
          </div>
          <div className="socialBtnOuter">    
            {/* <SocialButton
              className="fbBtn socialBtn"
              provider="facebook"
              // appId="643773183184460"
              //development velocamp.hubcelab.in
              // appId="2944648328970041"
              // appId="377098063704138"
              appId={Constants.FACEBOOK_APP_ID}
              onLoginSuccess={this.handleFBLogin}
              onLoginFailure={this.handleFBLoginFailure}
            >
              <img
                className="socialIcon"
                alt="Login with facebook"
                src={"/images/login/fb.svg"}
              />
              <span>Facebook</span>
            </SocialButton> */}



             <SocialButton
              className="googleBtn socialBtn"
              provider="google"
              //appId="513730001544-bokue06vc3dnnvi01frkao7fp6n8j0fo.apps.googleusercontent.com"
              // appId="903778737118-bs5n5qp7g11vd4fu4mbuotodha79scbc.apps.googleusercontent.com"
              appId={Constants.GOOGLE_LOGIN_CLIENT_ID}
              //appId="903778737118-u00cqf8bfjc1s2ido8mbqoinj3jgt62p.apps.googleusercontent.com"
              onLoginSuccess={this.handleGoogleLogin}
              onLoginFailure={this.handleGoogleLoginFailure}
            >
             
              <img
                className="socialIcon"
                alt="Login with google"
                src={"images/login/google.svg"}
              />
              <span>Google</span>
            </SocialButton>
{
  !IS_CERTIFICATE_APP &&

              <div style={{position:'relative'}}>
                <div className="btn-icon">
                <BiUserCircle size={18}/>
                </div>
                <button onClick={()=>this.props.history.push('/guest/bib-search')} className="login-btn">Use as Guest</button>
             </div>
              }
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Login);
